<template>
  <div>
    <a-form-model @submit.prevent="$emit('submit')" spellcheck="false" ref="form" :model="form" :rules="rules">
      <b-row>
        <b-col cols="12">
          <a-form-model-item
            label="Title"
            extra="This title reflects how it appears on your site."
            name="title"
            prop="title">
            <a-input :disabled="loading" required v-model="form.title" />
          </a-form-model-item>
          <a-form-model-item
            label="Description"
            name="description"
            prop="description">
            <a-textarea :disabled="loading" v-model="form.description" />
          </a-form-model-item>
          <a-form-model-item
            label="Parent"
            name="parent_id"
            prop="parent_id">
            <a-tree-select
              :disabled="loading"
              allow-clear
              :tree-data="categoryTree.map((category) => {
                return {
                  ...category,
                  disabled: this.form.id === category.id,
                }
              })"
              v-model="form.parent_id"
              tree-default-expand-all
              :loading="loading"
              placeholder="Select parent" />
          </a-form-model-item>
          <a-divider></a-divider>
          <div class="text-right">
            <b-button :disabled="loading" variant="primary" @click="submit()">
              {{ type === 'create' ? 'Add New Category' : 'Save' }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </a-form-model>

    <!-- Media Finder for Featured/Hero Image -->
    <b-modal hide-footer id="media-finder" size="xl" body-class="p-0 bg-light" centered title="Add Media">
      <MediaFinder :current="[form.image_media_id]" v-on:insert="applyMedia" />
    </b-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MediaFinder from '../media/finder'

export default {
  name: 'mainForm',
  props: {
    type: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      rules: {
        title: [{ required: true, message: 'Please insert title' }],
      },
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapState(['settings']),
    ...mapState('category', {
      form: state => state.form,
      categories: state => state.data,
      categoryTree: state => state.dataTree,
    }),
    loading() {
      return this.$store.state.category.loading
    },
  },
  methods: {
    submit() {
      const vm = this

      vm.$set(vm.form, 'categories', vm.category_ids)
      vm.$refs.form.validate(valid => {
        if (valid) {
          vm.$emit('submit')
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    },
    // Media
    removeMedia() {
      const vm = this
      vm.$set(vm.form, 'image_media_id', '')
      vm.$set(vm.form, 'image_media', {})
    },
    mediaFinderOpen() {
      const vm = this

      vm.$bvModal.show('media-finder')
    },
    applyMedia(media, medias) {
      // console.log(media, medias)
      const vm = this
      vm.$set(vm.form, 'image_media', media)
      vm.$set(vm.form, 'image_media_id', media.id)
      vm.$bvModal.hide('media-finder')
    },
  },
  components: {
    MediaFinder,
  },
  destroyed() {
    this.$store.commit('category/CLEAR_DATA')
    this.$store.commit('category/CLEAR_FORM')
  },
}
</script>

<style lang="scss">
@import "./style.module.scss";
</style>
